//
// Custom Countdown
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.countdown-custom {
  .countdown {
    &:before { 
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    &-section {
      display: inline-block;
      width: $countdown-custom-section-width;
      height: $countdown-custom-section-height;
      padding-top: $countdown-custom-section-inset-top;
      text-align: center;
      border-radius: $countdown-custom-section-border-radius;

      &:nth-child(5) {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        &:nth-child(5) {
          display: inline-block;
        }
      }
    }
    &-row {
      display: inline-block;
      vertical-align: middle;
    }
    .countdown-section + .countdown-section {
      margin-left: $countdown-custom-offset-left;
    }
    &-amount {
      display: block;
      font-weight: $countdown-custom-amount-weight;
      font-size: $countdown-custom-amount-size;
      line-height: $countdown-custom-amount-line-height;
      text-transform: uppercase;
      color: $gray-800;
    }
    &-period {
      display: block;
      font-size: $countdown-custom-period-size;
      line-height: $countdown-custom-period-line-height;
      color: $countdown-custom-period-color;
    }
    @include media-breakpoint-up(sm) {
      &-section {
        width: $countdown-custom-xs-section-width;
        height: $countdown-custom-xs-section-height;
        padding-top: $countdown-custom-xs-section-inset-top;
        border-width: $countdown-custom-xs-section-border-width;
      }
      &-amount {
        line-height: $countdown-custom-amount-xs-line-height;
      }
      .countdown-section + .countdown-section {
        margin-left: $countdown-custom-xs-offset-left;
      }
    }
    @include media-breakpoint-up(md) {
      &-section {
        width: $countdown-custom-sm-section-width;
        height: $countdown-custom-sm-section-height;
        padding-top: $countdown-custom-sm-section-inset-top;
      }
      .countdown-section + .countdown-section {
        margin-left: $countdown-custom-sm-offset-left;
      }
      &-amount {
        font-size: $countdown-custom-sm-amount-size;
      }
      &-period {
        font-size: $countdown-custom-sm-period-size;
      }
    }
    @include media-breakpoint-up(lg) {
      .countdown-section + .countdown-section {
        margin-left: $countdown-custom-md-offset-left;
      }
    }
  }

  // Countdown Modern
  &.countdown-modern {
    .countdown-section {
      position: relative;
      z-index: 1;

      .countdown-period{
        display: none;
      }

      .countdown-letter {
        position: $countdown-modern-position;
        top: $countdown-modern-position-top;
        right: $countdown-modern-position-left;
        left: $countdown-modern-position-right;
        z-index: $countdown-modern-z-index;
        text-align: center;
        font-size: $countdown-modern-size;
        color: $countdown-modern-color;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          font-size: $countdown-modern-sm-size;
          top: $countdown-modern-sm-position-top;
        }
      }
    }
  }

  // Countdown Ellipse
  @include media-breakpoint-up(sm) {
    &.countdown-ellipse .countdown-section {
      border: $countdown-ellipse-xs-border;
    }
  }
}


// Countdown Inline
// --------------------------------------------------

.countdown-inline {
  font-size: $countdown-inline-size;

  .countdown-section {
    .countdown-amount, .countdown-period {
      margin-right: $countdown-inline-offset-right;
    }
    .countdown-amount {
      font-weight: $countdown-inline-amount-weight;
      margin-left: $countdown-inline-amount-offset-left;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: $countdown-inline-xs-size;

    .countdown-amount, .countdown-period {
      margin-right: $countdown-inline-offset-xs-right;
    }
    .countdown-amount {
      margin-left: $countdown-inline-amount-xs-offset-left;
    }
  }
}

