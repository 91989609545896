//
// ToTop
// --------------------------------------------------

.ui-to-top {
  box-shadow: $to-top-box-shadow;
  position: fixed;
  right: $to-top-position-right;
  bottom: $to-top-position-bottom;
  overflow: hidden;
  z-index: 1;

  transform: translateY(100px);

  &:focus{
    color: $to-top-hover-color;
  }

  &:hover {
    color: $to-top-hover-color;
    background: $to-top-hover-background;
  }

  &.active{
    transform: translateY(0);
  }
}

.mobile .ui-to-top,
.tablet .ui-to-top {
  display: none !important;
}

@include media-breakpoint-up(sm) {
  .ui-to-top {
    right: $to-top-position-xs-right;
    bottom: $to-top-position-xs-bottom;
  }
}


